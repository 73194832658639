import React, { useEffect, useState } from "react"
import { Column, Container, Row } from "../components/Grid"
import HeroHome from "../components/HeroHome"
import Icon from "../components/Icon"
import Layout from "../components/Layout"
import OutboundLink from "../components/OutboundLink"
import PageCards from "../components/PageCards"
import Section from "../components/Section"
import SEO from "../components/Seo"
import Subscribe from "../components/Subscribe"
import Video from "../components/Video"
import RxBottle from "../images/theme/img-Rx-bottle.png"
import AboutPharmTeach from "../images/theme/img-hp-icon-pharm-teach.png"

const IndexPage = ({ data }) => {
  const [hostname, setHostname] = useState("")
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
    setHostname(
      window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")
    )
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      {/***************  HERO ***************/}
      <Section className="hero__home">
        <HeroHome
          title="Dose of Knowledge"
          subheading="A Pharmacists Teach Program"
          image="img-hp-hero-heart@2x.png"
        />
      </Section>

      {/***************  CALLOUT  ***************/}
      <Section className="callout">
        <Container>
          <Row>
            <Column span={12}>
              <Subscribe
                trigger={
                  <button>
                    <img
                      src={RxBottle}
                      className="rx-bottle"
                      alt="Dose of Knowledge Rx Bottle"
                    />
                    <span>Signup for Updates Today!</span>{" "}
                    <Icon name="rightarrow" inline />
                  </button>
                }
              />
            </Column>
          </Row>
        </Container>
      </Section>

      {/***************  ABOUT BE VAPE FREE ***************/}
      <Section className="about-program lightgray">
        <Container>
          <Row>
            <Column span={6}>
              <div className="embed-container">
                {" "}
                {loaded && (
                  <Video
                    guidSrc="b6890a90-89fb-4a77-a2fb-cc10746a7189"
                    posterSrc="img-hp-nflpa-adrianna.jpg"
                  />
                )}
              </div>
            </Column>
            <Column span={5}>
              <h2>About the Program</h2>
              <p>
                CVS Health and Discovery Education have partnered to expand the{" "}
                <strong>Pharmacists Teach</strong> program into the classroom
                with a no-cost prevention program,{" "}
                <strong>Dose of Knowledge</strong>. The program strives to
                empower educators and pharmacists to address substance misuse
                and educate students to make good decisions for the health and
                well-being of themselves and their community.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <PageCards
            cards={[
              {
                to: "/educators/",
                label: "Educators",
                image: "img-hero-infographic-educators.png",
                description:
                  "Find standards-aligned resources to start the conversation around substance misuse in the classroom.",
              },
              {
                to: "/pharmacists/",
                label: "Pharmacists",
                image: "img-hero-infographic-pharmacists.png",
                description:
                  "Bring your expertise into the classroom with easy-to-implement lesson plans designed to help students make healthy decisions around prescription and illicit drugs.",
              },
              {
                to: "/students/",
                label: "Students",
                image: "img-hero-infographic-students.png",
                description:
                  "Find interactive resources designed to empower students to make smart, healthy choices in the face of peer pressure.",
              },
              {
                to: "/caregivers/",
                label: "Caregivers",
                image: "img-hero-infographic-caregivers.png",
                description:
                  "Support students at home with evidence-based resources that can be used to guide discussions about the dangers of substance misuse.",
              },
            ]}
          />
        </Container>
      </Section>

      {/***************  ABOUT PHARMACISTS TEACH INFO ***************/}
      <Section className="about-pharmacists darkgray-background">
        <Container>
          <Row>
            <Column span={3}>
              <img
                src={AboutPharmTeach}
                className="pharma-teach-placeholder"
                alt="Dose of Knowledge Rx Bottle"
              />
            </Column>
            <Column span={9}>
              <h2>About Pharmacists Teach</h2>
              <p>
                CVS Health created Pharmacists Teach in 2015 to bring
                pharmacists into the classroom to help educate the public about
                the challenges surrounding substance misuse. The Pharmacists
                Teach program has now been expanded to include{" "}
                <strong>Dose of Knowledge</strong>, a program created in
                partnership with Discovery Education to provide
                standards-aligned resources to educators and pharmacists across
                the U.S. The <strong>Pharmacists Teach</strong> program strives
                to reach nearly 2M students by 2023 and to empower students
                across the country to make healthy choices to prevent the misuse
                of prescription and illicit drugs.
              </p>
              <OutboundLink to="https://cvshealth.com/social-responsibility/corporate-social-responsibility/pharmacists-teach">
                <button className="global__button darkgray">Learn More</button>
              </OutboundLink>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        gaId
      }
    }
  }
`

export default IndexPage
